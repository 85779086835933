<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Guías Compras Sin Transporte</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">
                    Guías Compras Sin Transporte
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row justify-content-between">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn bg-gradient-secondary"
                      data-toggle="modal"
                      data-target="#modal-form-import"
                      style="cursor: pointer"
                      v-if="$store.getters.can('hidrocarburos.guiaCompraSTrans.importExcel')"
                      @click="$refs.guiaCompraSinTransporteImportExcel.limpiarModal()"
                    >
                      Importar Excel
                      <i class="fas fa-file-import"></i>
                    </button>
                  </div>
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn bg-gradient-success"
                      v-if="$store.getters.can('hidrocarburos.guiaCompraSTrans.exportPlantilla')"
                      @click="exportExcel()"
                    >
                      Exportar Plantilla
                      <i class="fas fa-cloud-download-alt"></i>
                    </button>
                  </div>
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn bg-gradient-info"
                      @click="getIndex()"
                    >
                      <i class="fas fa-sync-alt"></i>
                    </button>
                    <button
                      type="button"
                      class="btn bg-gradient-danger"
                      @click="resetFilter()"
                    >
                      <i class="fas fa-broom"></i>
                    </button>
                    <button
                      type="button"
                      class="btn bg-gradient-primary"
                      style="cursor: pointer"
                      @click="crearGuiaIndividual()"
                      v-if="$store.getters.can('hidrocarburos.guiaCompraSTrans.create')"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body pb-2">
                <div class="row justify-content-center">
                  <div class="form-group col-md-2">
                    <label>Número de Guía</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.numero"
                      @change="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Transportadora</label>
                    <v-select
                      v-model="transportadora"
                      class="form-control form-control-sm p-0"
                      placeholder="Razón Social"
                      label="razon_social"
                      :options="listasForms.transportadoras"
                      @input="selectTransportadora()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Tipo Sitio</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="sitio_type"
                      @change="selectSitioType()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="listaSitio in listasForms.sitio_type"
                        :key="listaSitio.numeracion"
                        :value="listaSitio.numeracion"
                      >
                        {{ listaSitio.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="form-group col-md-4"
                    v-if="this.filtros.sitio_type == 'App\\Sitio'"
                  >
                    <label>Sitio</label>
                    <v-select
                      v-model="sitio"
                      class="form-control form-control-sm p-0"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.sitios"
                      @input="selectSitio()"
                    ></v-select>
                  </div>
                  <div
                    class="form-group col-md-4"
                    v-else-if="this.filtros.sitio_type == 'App\\PuntoGestion'"
                  >
                    <label>Punto Gestión</label>
                    <v-select
                      v-model="punto_gestion"
                      class="form-control form-control-sm p-0"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.puntos_gestion"
                      @input="selectPunto()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Producto</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.producto"
                      @change="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Vehículo</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.placa"
                      @keyup="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Remolque</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.remolque"
                      @keyup="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-5">
                    <label>Destino</label>
                    <v-select
                      v-model="destino"
                      class="form-control form-control-sm p-0"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.sitios"
                      @input="selectDestino()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha Guía</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_creacion"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha Expedición</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_expedicion"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha Salida</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_hora_descargue"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Estado</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="filtros.estado"
                      @change="getIndex()"
                    >
                      <option value>Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  id="guias"
                  class="table table-bordered table-striped table-hover table-sm"
                  style="font-size: 0.90em"
                >
                  <thead class="bg-dark">
                    <tr>
                      <th class="text-center">Número Guía</th>
                      <th class="text-center">Transportadora</th>
                      <th class="text-center">Sitio / Punto Gestión</th>
                      <th class="text-center">Producto</th>
                      <th class="text-center">Conductor</th>
                      <th class="text-center">Vehículo</th>
                      <th class="text-center">Remolque</th>
                      <th class="text-center">Destino</th>
                      <th class="text-center">Fecha Guía</th>
                      <th class="text-center">Fecha Expedición</th>
                      <th class="text-center">Fecha Salida</th>
                      <th class="text-center">Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(guia, index) in guias.data">
                      <tr
                        data-widget="expandable-table"
                        aria-expanded="false"
                        class="bg-light"
                        :key="index"
                      >
                        <td class="text-center">{{ guia.numero_guia }}</td>
                        <td class="text-center">
                          {{ guia.nombre_transportadora }}
                        </td>
                        <td class="text-center">
                          <span
                            class="badge"
                            :class="
                              guia.sitio_type == 'App\\Sitio'
                                ? 'bg-info'
                                : 'bg-info'
                            "
                          >
                            {{
                              guia.sitio_type == "App\\Sitio"
                                ? "Sitio"
                                : "Punto Gestión"
                            }}</span
                          >
                          {{ guia.sitio.nombre }}
                        </td>
                        <td>{{ guia.producto_liquido }}</td>
                        <td class="text-center">
                          {{ guia.nombre_conductor.toUpperCase() }}
                          <span class="badge bg-navy">
                            {{ guia.documento_conductor }}
                          </span>
                        </td>
                        <td class="text-center">
                          {{ guia.vehiculo.toUpperCase() }}
                        </td>
                        <td class="text-center">
                          {{ guia.remolque.toUpperCase() }}
                        </td>
                        <td class="text-center">
                          {{ guia.sitio_destino.nombre }}
                        </td>
                        <td class="text-center">
                          {{ guia.created_at.slice(0, 10) }}
                        </td>
                        <td class="text-center">
                          {{ guia.fecha_expedicion.slice(0, 10) }}
                        </td>
                        <td class="text-center">
                          {{ guia.fecha_hora_cargue.slice(0, 10) }}
                        </td>
                        <td class="text-center">
                          <span class="badge badge-primary">
                            <i class="fas fa-check"></i>
                            {{ guia.estadoGuia }}
                          </span>
                        </td>
                      </tr>
                      <tr class="expandable-body d-none" :key="index + 'a'">
                        <td colspan="13">
                          <div class="card-body p-0">
                            <div class="row">
                              <div
                                class="col-md-12 bg-light image-preview-input"
                              >
                                <div class="row">
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-city"></i>
                                        {{ guia.ciudad_origen.nombre }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Ciudad Origen</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-building"></i>
                                        {{ guia.ciudad_destino.nombre }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Ciudad Destino</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-map-pin"></i>
                                        {{ guia.codigo_destino }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Código Destino</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-map-marked-alt"></i>
                                        {{ guia.direccion_destino }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Dirección Destino</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-1"></div>
                                  <div class="col-sm-4">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-people-arrows"></i>
                                        {{ guia.despachado_a }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Despachado</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-2">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-stopwatch-20"></i>
                                        {{ guia.hora_adicional }}
                                        <small>Hrs</small>
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Hr Adicional</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-5">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="far fa-clock"></i>
                                        {{ guia.horas_vigencia
                                        }}<small>Hrs</small>
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Hr Vigencia</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-truck-moving"></i>
                                        {{ guia.bsw }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Bsw</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-temperature-low"></i>
                                        {{ guia.analisis_laboratorio }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Análisis Laboratorio</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-truck"></i>
                                        {{ guia.api }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Api</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-hockey-puck"></i>
                                        {{ guia.azufre }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Azufre</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-1"></div>
                                  <div class="col-sm-4">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-mountain"></i>
                                        {{ guia.gov }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Gov</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-2">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-hockey-puck"></i>
                                        {{ guia.nsv }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Nsv</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-5">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-truck-moving"></i>
                                        {{ guia.gsv }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Gsv</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-water"></i>
                                        {{ guia.tipo_producto.nombre }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Tipo Producto</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-caravan"></i>
                                        {{ guia.tipo_vehiculo.nombre }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Tipo Vehículo</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-user"></i>
                                        {{ guia.user.name }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Usuario Guía</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-thermometer-full"></i>
                                        {{ guia.factor_temperatura }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Factor Temperatura</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="card-footer alert-default-info">
                <div class="float-left" v-if="guias.total">
                  Mostrando del
                  <b
                    ><span class="badge bg-navy">
                      {{ guias.from }}
                    </span></b
                  >
                  al
                  <b
                    ><span class="badge bg-navy">
                      {{ guias.to }}
                    </span></b
                  >
                  de un total:
                  <b>
                    <span class="badge bg-navy">
                      {{ guias.total }}
                    </span></b
                  >
                  Registros
                </div>
                <div class="float-left" v-else>
                  <span class="badge badge-danger"
                    >No hay registros para mostrar</span
                  >
                </div>
                <pagination
                  :data="guias"
                  @pagination-change-page="getIndex"
                  :limit="10"
                  class="float-right mb-0"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <GuiaCompraSinTransporteImportExcel
      ref="guiaCompraSinTransporteImportExcel"
    />
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import GuiaCompraSinTransporteImportExcel from "./GuiaCompraSinTransporteImportExcel";
import Loading from "../../../../components/Loading";
import $ from "jquery";

export default {
  name: "GuiasCompraSinTransporte",

  components: {
    Loading,
    pagination,
    vSelect,
    GuiaCompraSinTransporteImportExcel,
  },

  data() {
    return {
      filtros: {},
      guias: {},
      transportadora: [],
      sitio: [],
      punto_gestion: [],
      destino: [],
      sitio_type: null,
      listasForms: {
        transportadoras: [],
        sitios: [],
        estados: [],
        sitio_type: [],
        puntos: [],
      },
      cargando: false,
    };
  },

  methods: {
    async getIndex(page = 1) {
      let me = this;
      me.cargando = true;
      await axios
        .get("/api/hidrocarburos/detGuiaCompraSTrans?page=" + page, {
          params: this.filtros,
        })
        .then(function(response) {
          me.cargando = false;
          var respuesta = response.data;
          me.guias = respuesta;
        });
    },

    /* Función Listas */
    async getTransportadoras() {
      let me = this;
      me.listasForms.transportadoras = [];
      var url = "api/admin/empresas/lista";
      await axios
        .get(url, {
          params: { linea_negocio_id: 2 },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.transportadoras = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getSitios() {
      let me = this;
      me.listasForms.sitios = [];
      var url = "api/admin/sitios/lista";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getPuntos() {
      await axios
        .get("/api/admin/puntos_gestion/lista", {
          params: {
            estado: 1,
          },
        })
        .then((response) => {
          this.listasForms.puntos_gestion = response.data;
        });
    },

    async getEstadosViajes() {
      await axios.get("/api/lista/39").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getSitioType() {
      await axios.get("/api/lista/25").then((response) => {
        this.listasForms.sitio_type = response.data;
      });
    },

    /* Función Seleccinar Listas */
    selectTransportadora() {
      this.filtros.transportadora_id = null;
      if (this.transportadora) {
        this.filtros.transportadora_id = this.transportadora.id;
      }
      this.getIndex();
    },

    selectSitio() {
      this.filtros.sitio_id = null;
      if (this.sitio) {
        this.filtros.sitio_id = this.sitio.id;
      }
      this.getIndex();
    },

    selectPunto() {
      this.filtros.punto_gestion_id = null;
      if (this.punto_gestion) {
        this.filtros.punto_gestion_id = this.punto_gestion.id;
      }
      this.getIndex();
    },

    selectDestino() {
      this.filtros.destino_id;
      if (this.destino) {
        this.filtros.destino_id = this.destino.id;
      }
      this.getIndex();
    },

    selectSitioType() {
      this.filtros.sitio_type = null;
      if (this.sitio_type == 1) {
        this.filtros.sitio_type = "App\\Sitio";
      }

      if (this.sitio_type == 2) {
        this.filtros.sitio_type = "App\\PuntoGestion";
      }
      this.getIndex();
    },

    exportExcel() {
      this.$parent.cargando = true;
      axios({
        method: "get",
        url: "/api/hidrocarburos/detGuiaCompraSTrans/exportPlantilla",
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          location.href = data.url;
          this.$swal({
            icon: "success",
            title: response.data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    /* Función Limpiar Campos */
    resetFilter() {
      this.sitio = null;
      this.sitio_type = null;
      this.transportadora = null;
      this.punto_gestion = null;
      this.destino = null;
      this.filtros = {};
      this.getIndex();
    },

    /* Función Formulario Crear */
    crearGuiaIndividual() {
      return this.$router.push({
        name: "/Hidrocarburos/GuiasCompraSinTransporteForm",
        params: {
          accion: "Crear",
          id: 0,
        },
      });
    },
  },

  async beforeMount() {
    await this.getEstadosViajes();
    await this.getSitios();
    await this.getTransportadoras();
    await this.getSitioType();
    await this.getPuntos();
  },

  async mounted() {
    await this.getIndex();
  },
};
</script>
